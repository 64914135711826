<template>
    <v-container id="dashboard" fluid tag="section" style="padding-right: 0px">

      <v-alert v-if="avisoPublicado==1"
      border="left"
      colored-border
      :color="avisoColor"
      elevation="2"

      class="mt-2"
      :style="{backgroundColor: avisoColorFondo}"
    >
    <table>
      <tr>
        <td style="width:50px;"><v-icon large :color="avisoColor">mdi-information</v-icon></td>
        <td> <span class="text-h4" v-html="avisoTitulo"> </span><br>
          <div class="texto-formateado" v-html="avisoMensaje"></div>
        </td>
      </tr>
    </table>



    </v-alert>

        <div class="center" :style="$store.state.user.roles.aviso_estado==1 ? 'margin-top: 0% ': 'margin-top: 5%' " >
          <v-card class="custom-card elevation-0" >
            <v-row>
              <v-col  cols="12" md="7" style="text-align: left;padding-top: 100px;" class="bg-secondary">
                <p class="ml-6"><b style="font-size: 1.5rem;" >Bienvenidos a portal!</b></p>

                <div class="ml-6" style="width:90px;height: 7px;background-color: #fff;"></div>
                <br>
                <p class="ml-6">Gestiona todos tus trámites automotores en un solo lugar,<br>
                de forma rápida y segura. <b>¡Todo lo que necesitas está aquí!</b></p>
              </v-col>
              <v-col  cols="12" md="5"  class="bg-secondary" style="text-align: right; ">

                <img width="400" height="400" src="/img/acara_logo_2.png" style="text-align: right; background-color: #fff;border-radius: 50% 0 0 50%; padding: 3%; margin:-2%"/>
              </v-col>
            </v-row>

          </v-card>
        </div>
    </v-container>
</template>

<script>

	export default {
    data: () => ({
      avisoPublicado:false,
      avisoColor:'',
      avisoColorFondo:'',
      avisoTitulo:'',
      avisoMensaje:'',

		}),
    created: function () {
      this.updateDataUser();


    },
     methods: {


     async updateDataUser() {
        try {

            //await this.$store.commit('SET_USER', Vue.prototype.$keycloak);


            this.avisoPublicado = this.$store.state.user.roles.aviso_estado,
            this.avisoColor     = parseInt(this.$store.state.user.roles.aviso_prioridad) ==1 ? 'primary':(parseInt(this.$store.state.user.roles.aviso_prioridad) ==2)? 'success': 'danger',
            this.avisoColorFondo     = parseInt(this.$store.state.user.roles.aviso_prioridad) ==1 ? '#0000FF10':(parseInt(this.$store.state.user.roles.aviso_prioridad) ==2)? '#00ff0010': '#ff000010',

            this.avisoTitulo    = this.$store.state.user.roles.aviso_titulo,
            this.avisoMensaje   = this.$store.state.user.roles.aviso_mensaje



        } catch (error) {
          console.error('Error al actualizar datos de usuario:', error);
        }
      }
    }


  };
</script>

<style scoped>
.custom-card {
  border-radius: 20px 0 0 20px;
  overflow: hidden;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1) !important;

}

.texto-formateado {
  white-space: pre-wrap; /* Respeta saltos de línea y tabulación */
  /*font-family: monospace;*/
}

</style>
